@import './colors.scss';

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}


/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-weight: 500;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
li {
  list-style: none;
}
/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html { 
  font-size: 62.5%; 
}
body {
  font-family: 'Montserrat', sans-serif;
  color: $black-color;
  font-size: 1.5rem;
  overflow-x: hidden;
  background-color: $bg-color;
  height: 500vh;


}
.wrapper {
  max-width: 1200px;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;
}
.wrpper-max{
  max-width: 1400px;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-s-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-row-center {
  display: flex;
  align-items: center;
}
.left {
  float: left;
}
.right {
  float: right;
}
a {
  text-decoration: none;
  -webkit-transition: .3s;
  transition: .3s;
}
.text-center {
  text-align: center;
}
.margin-center {
  margin: 0 auto;
}
.relative {
  position: relative;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.pointer {
  cursor: pointer;
}
.shadow {
  -webkit-box-shadow: 0px 2px 6px 2px rgba(92,112,133,0.1);
  -moz-box-shadow: 0px 2px 6px 2px rgba(92,112,133,0.1);
  box-shadow: 0px 2px 6px 2px rgba(92,112,133,0.1);
}
/*********************** SPACINGS ***********************/
.padding5 {
  padding: 0.5rem 0;
}
.padding10 {
  padding: 1rem 0;
}
.padding15 {
  padding: 1.5rem 0;
}
.padding20 {
  padding: 2rem 0;
}
.padding30 {
  padding: 3rem 0;
}
.padding40 {
  padding: 4rem 0;
}
.padding60 {
  padding: 6rem 0;
}
/*********************** FONTS ***********************/
h1,h2,h3,h4,h5,h6 {
  font-weight: 500;
}
.weight350{
  font-weight: 350;
}
.weight400 {
  font-weight: 400;
}
.weight500 {
  font-weight: 500;
}
.weight800 {
  font-weight: 800;
}
.font9 {
  font-size: 0.9rem;
}
.font10 {
  font-size: 1.0rem;
}
.font11 {
  font-size: 1.1rem;
}
.font12 {
  font-size: 1.2rem;
}
.font13 {
  font-size: 1.3rem;
}
.font14 {
  font-size: 1.4rem;
}
.font15 {
  font-size: 1.5rem;
}
.font18 {
  font-size: 1.8rem;
}
.font20 {
  font-size: 2rem;
}
.font22{
  font-size: 2.2rem;
}
.font23 {
  font-size: 2.3rem;
}
.font24 {
  font-size: 2.4rem;
}
.font26{
  font-size:2.6rem;
}
.font30 {
  font-size: 3rem;
}
.font35 {
  font-size: 3.5rem;
}
.font40 {
  font-size: 4rem;
}
.font60 {
  font-size: 5rem;
  line-height:6rem;
}

p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 3rem;
}
// Hover color for navbar
.active-link {
  color: $main-color;
}

.horizontal-line-full{
  display: relative;
  width: 100%;
  height: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color:white;
}

.horizontal-line-half{
  display: relative;
  width: 100%;
  height: 2px;
  margin-top: 5%;
  background-color:gray;
}

.some-heading-full-display{
  padding:10px;
  margin-top:5%;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom:5px;
  background-color: $main-color;
  font-family:Arial, Helvetica, sans-serif;
  font-weight:500; 
  font-size:35px;
  color: black;
  text-align: center;
  border-radius: 10px;

}

.some-heading-half-display{
  padding-left: 10px;
  padding-right: 10px;
  width: max-content;
  height: max-content;
  border: 2px solid $main-color;
  border-radius: 10px;
  font-family:Arial;
  text-align: center;
  font-size:24px;
  color: white;
 

}

.box-yellow-border{
  padding-top: 2% ;
  font-size: 1.4rem;
  font-weight: 800;
  margin-left: 10%;
  margin-right: 10%;
  border: 2px solid $main-color;
  background-color: transparent;
}

.text-yellow{
  color: $main-color;
}

.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  font-size: 50px;
  color: #e0dcdc;
}

.swiper-button-next::after {
  display: none;
}

.cardDiv{
  background-color:$bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% ;
    .card{
      height: 10%;
      width: max-content;
      border-radius: 5%;
      background-color: $main-color;
      padding: 15px 20px;
      cursor: pointer;
      p{
        font-size: 2rem;
        font-weight: 800;
      }
    }
}

// scoll bar css

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $bg-color;
  border: 2px solid #b4b4b4;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $bg-color; 
  border-radius: 6px;
  border-top: 2px solid #b4b4b4;
  border-bottom: 2px solid #b4b4b4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-color; 
}

@media screen and (max-width:500px) {
  
  .some-heading-full-display{
    margin: 5% 15%;
    font-size: 2.2rem;
  }
}



