@import '../../style/App.scss';

#skills {
  padding: 20px 0 40px 0;
  color: white;
  background-color: rgb(27, 26, 26);
}

.my-masonry-grid {
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  margin-left: -30px; 
  width: auto;
}
.mint__gallery {
  padding-left: 30px; 
  background-clip: padding-box;
}
 

.mint__gallery > div { 
  margin-bottom: 30px;
}
