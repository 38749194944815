@import '../../style/App.scss';

.project__box {
  width: max-content;
  // position: relative;
  display: flex;
  justify-content: center;
  margin: 6% auto;
  padding: 2%;
  border-radius: 10px;
  color: #000;
  background-color: $white-color;

  .project__image {
    width: 180px;
    height: 220px;
    display: flex;
    margin: 10% auto;
    background-color: black;
    border-radius: 10px;
    border: 2px solid black;


    img {
      width: 100%;
      height: 100%;
      display: block;
      background-color: #000;
      border-radius: 10px;
    }

  }

  .project_title {
    text-transform: uppercase;
    margin: auto 5%;
    margin-top: 2%;
    text-align :center;
    color: $main-color;
    background-color: $bg-color;
    border-radius: 10px;
    padding: 2% 10%;
    
    p{
      font-family:'Times New Roman', Times, serif;
      margin: auto;
      color: $main-color;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .project_details{
    border: 2px solid black;
    border-radius: 10px;
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 3%;  
    
    .project__info {
      align-items: center;
      
      p{
        font-size: 1.6rem;
        font-weight: 800;
      }
    }
    .action-buttons-div{
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .action-button{
      margin: 2% 3%;
    }
  }

  
}

.project__box:hover{
  transform: scale(1.02);
}


@media screen and (max-width: 1100px) {
  .project__box {
    width: 90%;

    .project__image {
      margin: 25% 5% ;
      width: 160px;
      height: 180px;
    }
    .project_title{
      margin: auto 10%;
      padding: 2%;
      p{
        font-size: 1.8rem;
      }
    }
    .project_details{
      margin: 3%;

      .project__info {
        padding: 3%; 
        
        p{
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

  }
  }

}
@media screen and (max-width: 770px) {
  .project__box {
    width: 90%;

    .project__image {
      margin: 5% auto ;
      width: 180px;
      height: 180px;
    }
  }  

}

@media screen and (max-width: 500px) {
  .project__box {
    width: 90%;
    .project__image {
      margin: 2% auto ;
    }
    .project_title{
      margin: auto 10%;
      padding: 2%;
    }

  }

}

