@import '../../style/App.scss';

#events {
  background-color: $bg-color;
}
.event-container{
  height: fit-content;
  margin: 0 auto;
  position: sticky;
  border: 4px solid $light-color;
  padding: 0.5% 1%;
}
@media screen and (max-width: 770px) {
.event-container{
  margin-top: 20%;
  border: 2px solid $light-color;
}
}

