@import '../../style/App.scss';

#about {
  padding: 60px 0;
  color: white;
}

p {
  text-align: center;
}

.resume_dow_btn {
  width: 25%;
  min-width: max-content;
  height: min-content;
  margin-top: 2%;
  background-color: $main-color;
  border: 2px solid $main-color;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  transition: 0.1s ease;

  * {
    color: black;
    display: inline-flex;
  }

  a {
    font-size: 1.6rem;
    font-weight: 800;
  }

  .download_icon {
    font-size: 25px;
    margin-left: 3%;
  }

}

.resume_dow_btn:hover {
  border: 2px solid $main-color;
  background-color: transparent;
  transform: scale(1.05);

  * {
    color: white;
  }
}

.about_box_li {
  margin-top: 5%;
}

.about_box_icon {
  display: flex;
  color: white;
  font-size: 25px;
  font-weight: bold;
  float: left;
}

.contact-box {
  margin: 10px;
  padding-left: 10%;
  padding-right: 10%;
}


.other-interest-sec {
  padding: 0% 5%;
  margin: 7% 0%;
}

@media screen and (max-width: 990px) {
  .resume_dow_btn {
    width: max-content;
    margin: auto;
    margin-top: 5%;
  }
}

@media screen and (max-width: 500px) {
  #about {
    text-align: center;
  }

  .resume_dow_btn {
    width: max-content;
    margin: auto;
    margin-top: 5%;
  }

  .download_cv_text {
    justify-content: center;
    text-align: center;
  }

  .about_box_title {
    text-align: center;
    // position: fixed;
  }

  .about_box_value {
    text-align: center;
    // position: fixed;
  }
}