@import '../../../style/App.scss';

.social_contact_div {
  width: 100%;
  color: white;
  padding-top: 3%;
  padding-bottom: 2%;
  background-color: $bg-color;
}

.contact__social {
  margin: 10px 0 10px 0;
  transition: 0.1s ease;
  cursor: pointer;
  transform: scale(2.8);

}

.contact__social:hover {
  transform: scale(3.2);
  color: #FFE600;
}

@media screen and (max-width: 990px) {
  .contact__social {
    text-align: center;
    width: 100%;

    img {
      margin: 0 auto;
    }
  }
}