.tooltip-container {
    position: relative;
  }
  
  .tooltip-box {
    visibility: hidden;
    width: max-content;
    background-color: white;
    color: #000;
    text-align: center;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    border: 2px solid black;
  }

  .tooltip-box-position{
    top: 20%;
    left: 105%; 
  }
  
  .tooltip-box.visible {
    visibility: visible;
  }
  
  .tooltip-arrow {
    position: absolute;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }

  .tooltip-arrow-position{
    top: 50%;
    right: 100%;
  }

  @media screen and (max-width:900px) {

     .tooltip-box {
         width: auto;
     }
  
    .tooltip-box-position{
        top: 120%; 
        left: 0%;
      }
    .tooltip-arrow-position{
        top: -10%;
        left: 50%;
        transform: rotate(90deg);
      }
}