@import '../../style/App.scss';

.event_photo__box {
  width: 250px;
  height: max-content;
  margin: 10% 10%;
  padding-bottom: 10%;
  background-color: rgb(14, 13, 13);
  border-radius: 5%;
  border : 1px solid #fff;

}

.event-photo{
  width: 250px;
  height: 250px;
  margin: auto;
  opacity: 1;
  transform: scale(0.9);
  display: flex;
  border-radius: 5%;
  border : 1px solid rgb(128, 124, 124);
  justify-content: center;
  img{
    border-radius: 5%; 
  }
}
.event-photo:hover{
  transform: scale(0.95);
  transition: 0.2s ease;
  opacity: 1.2;
}

.event_photo_box-info {
  background-color: $main-color;
  width: 70%;
  margin: auto;
  padding: 2%;
  justify-content: center;
  align-items: center;  
  position: relative;
  border-radius: 10px;

}
.event_name{
    color: $black-color;
}
.event_place{
  color: #0000009f;
}