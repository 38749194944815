@import '../../style/App.scss';

.projects {
  padding: 2%;
  color: white;
  background-color: $bg-color;
  padding-bottom:5% ;

  .project_div{
    width: 95%;
    margin: 2% auto;
    border: 2px solid #FFE600;

  }

  .my-masonry-grid {
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;

  }
  .mint__gallery {
    background-clip: padding-box;
  }
   
  
  .mint__gallery > div { 
    margin-bottom: 30px;
  }
}

.more-project-btn{
  width: fit-content;
  margin: 20% 30%;
  background-color: $main-color;
  border-radius: 10px;
  padding: 3% 6%;
  border: 2px solid $main-color;

  .action-button-div{
    .action-button-title{
      font-size: 1.8rem;
    }
    .MuiSvgIcon-root{
      width: 3em;
      height: 3rem;
    }
}

}

.more-project-btn:hover{
  background-color: $bg-color;
  border: 2px solid $main-color;

.action-button-div{
  background-color: $bg-color;
}
.action-button-div:hover{
  transform: scale(1);
}
.action-button-title{
  color: $white-color;
}
.MuiSvgIcon-root{
  color: $white-color;
  }
}  
 
@media screen and (max-width: 990px) {
  #projects { 
    text-align: center;
  }
  .more-project-btn{
    width: fit-content;
    margin: 20% 15%;
  } 
}
@media screen and (max-width: 600px) {
  .projects .project_div{
    width: 90%;
  }

  .more-project-btn{
    margin: 15% 15%;
    padding: 2% 10%;

      .MuiSvgIcon-root{
      width: 3rem;
      height: 3rem;
      }
      .action-button-title{
        font-size: 1.8rem;
      }
  }

}