@import '../../style/App.scss';

// DESKTOP NAVBAR
.Navbar {
  position: sticky;
  top: 0;
  left: 0;
  // background-color: $white-color;  
  width: 100%;
  padding: 9px 0;
  transition:  0.3s ease;
  z-index: 100;
  color: white;
  background-color: rgb(27, 26, 26);


  .mobile__menu {
    display: none;
    width: min-content;
    // float: right;
    padding: 10px 15px;
    cursor: pointer;
  }
  .desktop__menu {
    display: block;
    

    ul {
      li {
        cursor: pointer;
        padding: 2rem 2.7rem;
        margin: 0 1rem;
      }
      li:last-child {
        margin-right: 0;
        padding-right: 0;
      }
      li:hover {
        color: #FFE600;
      }
    }
  }
}
.open_mobile_nav{
  background-color: $black-color;
}
.extraLargeNavbar {
  padding: 20px 0;
}

// MOBILE NAVBAR
.mobile__navbar {
  height: 100%;
  position: fixed;
  margin-top: 20%;
  top: 0;
  right: -100%;
  background-color: $black-color;
  z-index: 1000;
  transition:  0.4s ease;
  padding: 20px;
  overflow-y: auto;
  
  .mobile__navbar-close {
    padding: 10px;
    margin-left: 80%;
    cursor: pointer;
    
  }
  .mobile__navbar-logo {
    padding: 1%;
    cursor: pointer;
  }
  .mobile__navbar-menu {
    padding-top: 5%;
    ul {
      li {
        cursor: pointer;
        color: $white-color;
        font-size: 2.5rem;
        padding: 8% 0;
      }
      li:hover {
        color: $main-color;
      }
    }
  }
}
.mobile__open {
  right: 0;
}

.small-nav{
  margin-top: 16%;

}

// BACKDROP
.backdrop {
  width: 100%;
  height: 100vh;
  background-color: $black-color;
  opacity: 0.8;
  position: fixed;
  margin-top: 20%;  
  top: 0;
  right: 0;
  z-index: 500;
  display: none;
}
.backdrop__open {
  display: block;
  top: 20%;
}

// MEDIA QUERIES
@media screen and (max-width: 720px) {
  .Navbar {
     width: 100%;
    .mobile__menu {
      display: block;
     
    }
    .desktop__menu {
      display: none;
    }
  }
  .mobile__navbar {
    width: 70%;
  }
}

@media screen and (max-width: 520px) {
  .mobile__navbar {
    width: 100%;
  }
}
