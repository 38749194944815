@import '../../style/App.scss';

.eduWork_Box {
  max-width: max-content;
  height: max-content;
  margin: 0 auto;
  margin-top: 10%;
  display: flex;
  cursor: pointer;

  .institute-round-image{
    // position: relative ;
    // display: flex;
    align-items: center;
    margin: auto;
    width: max-content;
    }

  .eduWork_Box_info {
    width: 100%;
    display:relative ;
    margin: auto;
    text-align: center;
    align-items: center;
    margin-top: 5px;    
    color: $white-color;

    p {
      margin-top: 2%;
    }
    p:nth-child(1) {
      margin-top: 5%;
    }
  }
}

@media screen and (min-width: 990px) {
  .eduWork_Box{
    padding: auto;
    margin-top: 4%;
    align-items: center;

    .eduWork_Box_info{
      p{
        width: max-content;
        font-size: 2rem;
        margin-top: 5%;
      }
      p:nth-child(3){
        font-size: 1.6rem;
      }
      p:nth-child(4){
        font-size: 1.6rem;
      }
    }
  }
}